<template>
  <v-card outlined class="rounded-lg" :loading="loading">
    <v-card-title>
      <v-chip  label dark :color="uptimeInfo.down_monitors > 0 ? 'red' : 'green'">{{ uptimeInfo.up_monitors }} / {{ uptimeInfo.total_monitors }}</v-chip>
      <span class="pl-2">Uptime Monitor</span>

      <v-spacer></v-spacer>
      <v-btn icon @click="getUptimeMonitors(); getUptimeInfo()"><v-icon>refresh</v-icon></v-btn>
      <v-btn icon href="https://dashboard.uptimerobot.com/monitors"><v-icon>open_in_new</v-icon></v-btn>
    </v-card-title>
      <v-list dense class="py-0">
        <span v-for="(webseite, index) in webseiten.slice(0, anz)" :key="index">
          <v-list-item>
            <v-list-item-icon class="pt-2 mr-4">
              <v-icon v-if="webseite.status === 0" color="grey">pause_circle</v-icon>
              <v-icon v-if="webseite.status === 1" color="grey">help</v-icon>
              <v-icon v-if="webseite.status === 2" color="green">check_circle</v-icon>
              <v-icon v-if="webseite.status === 8" color="orange">error</v-icon>
              <v-icon v-if="webseite.status === 9" color="red">error</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ webseite?.url }}</v-list-item-title>
              <v-list-item-subtitle>{{ webseite?.webseite?.kunde | user(kunden) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="webseite.status !== 2">
              <v-btn icon :href="webseite.url" target="_blank" rel="noopener noreferrer"><v-icon>open_in_new</v-icon></v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-progress-circular  
                rotate="-90"
                height="42" 
                :value="webseite.uptimeratio"
                :color="webseite.uptimeratio > 99 ? 'green' : (webseite.uptimeratio > 90 ? 'orange' : 'red')"
              >
              <span class="text-caption">{{ parseInt(webseite.uptimeratio).toFixed(0) }}</span>
              </v-progress-circular>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index < webseiten.length - 1"></v-divider>
        </span>
        <v-list-item class="d-flex justify-center" v-if="anz < webseiten.length">
          <v-btn text @click="anz += 10">mehr anzeigen</v-btn>
        </v-list-item>
        <v-list-item class="d-flex justify-center" v-else>
          <v-btn text @click="anz = 10">einklappen</v-btn>
        </v-list-item>
      </v-list>
  </v-card>
</template>

<script>
  import axios from "axios";
  export default {
    data () {
      return {
        loading: false,
        webseiten: null,
        lastCheck: null,
        anz: 3,
        uptimeInfo: {
          down_monitors: 0,
          up_monitors: 0,
          total_monitors: 0,
        },
      }
    },
    computed: {
      kunden () { return this.$store.state.kunden.kunden },
    },
    methods: {
      async getUptimeInfo() {
        try {
          const response = await axios.get("https://api-kejrsceu4a-uc.a.run.app/api/getUptimeInfo");
          if (response.data.data.success) {
            this.uptimeInfo = {
              total_monitors: response.data.data.res.account.total_monitors_count,
              up_monitors: response.data.data.res.account.up_monitors,
              down_monitors: response.data.data.res.account.down_monitors
            }
          }
        } catch (error) {
          console.error("Error fetching uptime data:", error.response?.data || error.message);
        }
      }, 
      async getUptimeMonitors() {
        const webseiten = this.$store.state.webseiten.webseiten;
        this.loading = true
        try {
          const response = await axios.get("https://api-kejrsceu4a-uc.a.run.app/api/getUptime");
          if (response.data.data.success) {
            this.webseiten = []
            this.webseiten = response.data.data.res.monitors.map((monitor) => ({
              webseite: webseiten.find((item) => item.url === monitor.url),
              url: monitor.url,
              status: monitor.status,
              uptimeratio: parseFloat(monitor.custom_uptime_ratio.split('-')[0]),
            }));
            const statusOrder = { 9: 0, 8: 1, 1: 2, 0: 3, 2: 4 }; // Reihenfolge der Statuswerte
            this.webseiten.sort((a, b) => {
              if (statusOrder[a.status] !== statusOrder[b.status]) {
                return statusOrder[a.status] - statusOrder[b.status]; // Nach Status sortieren
              }
              return a.uptimeratio - b.uptimeratio; // Bei gleichem Status nach uptimeratio sortieren
            });
          }
          this.loading = false
        } catch (error) {
          console.error("Error fetching uptime data:", error.response?.data || error.message);
          this.loading = false
        }
      }, 
    },
    mounted () {
      this.getUptimeMonitors()
      this.getUptimeInfo()
    }
  }
</script>
