<template>
  <v-card outlined class="rounded-lg" :loading="loading">
    <v-card-title>
      <span class="pl-2">MainWP Monitor</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="getMainWP(); getMainWPInfo()"><v-icon>refresh</v-icon></v-btn>
      <v-btn icon href="https://benediktbreuer.de/wp-admin/admin.php?page=mainwp_tab"><v-icon>open_in_new</v-icon></v-btn>
    </v-card-title>
      <v-list dense class="py-0">
          <v-list-item v-for="(item, index) in upgrades" :key="index">
            <v-list-item-icon class="pt-2 mr-4">
              {{ mainWPInfo[item.value] }}
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.label }}-Updates</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn class="primary" disabled>Update</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
      </v-list>
  </v-card>
</template>

<script>
  import axios from "axios";
  export default {
    data () {
      return {
        loading: false,
        webseiten: null,
        lastCheck: null,
        anz: 3,
        upgrades: [
          { value: "wp_upgrades", label: "WP-Core" },
          { value: "theme_upgrades", label: "Theme" },
          { value: "plugin_upgrades", label: "Plugin" },
          { value: "translation_upgrades", label: "Übersetzung" }
        ],
        mainWPInfo: {
          plugin_upgrades: 0,
          theme_upgrades: 0,
          wp_upgrades: 0,
          translation_upgrades: 0,
        },
      }
    },
    computed: {
      kunden () { return this.$store.state.kunden.kunden },
    },
    methods: {
      async getMainWPInfo() {
        try {
          const response = await axios.get("https://api-kejrsceu4a-uc.a.run.app/api/mainWPInfo");
          if (response.data.data.success) {
            console.log(response.data.data.res.data)
            
            const resData = response.data.data.res.data;
            this.mainWPInfo.plugin_upgrades = 0;
            resData.forEach(obj => {
              ['plugin_upgrades', 'theme_upgrades', 'wp_upgrades', 'translation_upgrades'].forEach(upgradeType => {
                if (obj[upgradeType]) {
                  try {
                    // Upgrade-Typ als JSON parsen
                    const parsedUpgrades = JSON.parse(obj[upgradeType]);
                    console.log(upgradeType, parsedUpgrades);
                    // Prüfen, ob parsedUpgrades ein Objekt ist, und Keys zählen
                    if (parsedUpgrades && typeof parsedUpgrades === 'object') {
                      const keysCount = Object.keys(parsedUpgrades).length;
                      this.mainWPInfo[upgradeType] = (this.mainWPInfo[upgradeType] || 0) + keysCount;
                    }
                  } catch (error) {
                    console.error(`Fehler beim Parsen von ${upgradeType}:`, error, obj[upgradeType]);
                  }
                }
              });
            });
            console.log("Gesamtlänge aller plugin_upgrades-Arrays:", this.mainWPInfo.plugin_upgrades);
          }
        } catch (error) {
          console.error("Error fetching uptime data:", error.response?.data || error.message);
        }
      }, 
      async getMainWP() {
        // const webseiten = this.$store.state.webseiten.webseiten;
        // this.loading = true
        // try {
        //   const response = await axios.get("https://api-kejrsceu4a-uc.a.run.app/api/getUptime");
        //   if (response.data.data.success) {
        //     this.webseiten = []
        //     this.webseiten = response.data.data.res.monitors.map((monitor) => ({
        //       webseite: webseiten.find((item) => item.url === monitor.url),
        //       url: monitor.url,
        //       status: monitor.status,
        //       uptimeratio: parseFloat(monitor.custom_uptime_ratio.split('-')[0]),
        //     }));
        //     const statusOrder = { 9: 0, 8: 1, 1: 2, 0: 3, 2: 4 }; // Reihenfolge der Statuswerte
        //     this.webseiten.sort((a, b) => {
        //       if (statusOrder[a.status] !== statusOrder[b.status]) {
        //         return statusOrder[a.status] - statusOrder[b.status]; // Nach Status sortieren
        //       }
        //       return a.uptimeratio - b.uptimeratio; // Bei gleichem Status nach uptimeratio sortieren
        //     });
        //   }
        //   this.loading = false
        // } catch (error) {
        //   console.error("Error fetching uptime data:", error.response?.data || error.message);
        //   this.loading = false
        // }
      }, 
    },
    mounted () {
      // this.getMainWP()
      this.getMainWPInfo()
    }
  }
</script>
