import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// initial state
const state = {
  zeiten: [],
  loading: false,
  error: null
}

// getters
const getters = {
  zeit: (state) => (id) => {
    return state.zeiten.find(zeit => zeit.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadZeiten ({commit}) {
    commit('setLoading', true)
    console.log('Zeiten laden...')
    firebase.firestore().collection('zeiten').orderBy('datum', 'desc')
    .onSnapshot(snapshot => {
      const zeiten = []
      snapshot.forEach(doc => {
        zeiten.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedZeiten', zeiten)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createZeit ({commit, getters}, payload) {
    console.log(payload)
    firebase.firestore().collection('zeiten').add(payload)
      .then(() => {
        commit('updateKalenderIcalFeed')
      })
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteZeitData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('zeiten').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteZeit', payload)
      commit('updateKalenderIcalFeed')
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateZeitData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('zeiten').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateKalenderIcalFeed')
      commit('updateZeit', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedZeiten (state, payload) {
    state.zeiten = payload
  },
  createZeit (state, payload) {
    state.zeiten.push(payload)
  },
  updateZeit (state, payload) {
    const zeit = state.zeiten.find(zeit => {
      return zeit.id === payload.id
    })
    if (payload.creatorID) {
      zeit.creatorID = payload.creatorID
    }
    if (payload.date) {
      zeit.date = payload.date
    }
    if (payload.bezahlt) {
      zeit.bezahlt = payload.bezahlt
    }
    if (payload.fahrer) {
      zeit.fahrer = payload.fahrer
    }
    if (payload.km) {
      zeit.km = payload.km
    }
    if (payload.title) {
      zeit.title = payload.title
    }
  },
  updateKalenderIcalFeed (state) {
    const ics = require('ics');
    const storage = getStorage();
    let events = [];
  
    state.zeiten.forEach(item => {
      let a = item.datum.split('-');
      let b = item.von.split(':');
      let c = item.datum.split('-');
      let d = item.bis.split(':');
      let bemerkung = item.anmerkung ? item.anmerkung : '';
      const event = {
        start: [parseInt(a[0]), parseInt(a[1]), parseInt(a[2]), parseInt(b[0]), parseInt(b[1])],
        end: [parseInt(c[0]), parseInt(c[1]), parseInt(c[2]), parseInt(d[0]), parseInt(d[1])],
        title: item.kundenname,
        description: bemerkung
      };
      events.push(event);
    });
  
    let icaltext = ics.createEvents(events).value;
    let filename = `webservice-breuer.ics`;
  
    // Firebase Storage Referenz erstellen
    const storageRef = ref(storage, `kalender/${filename}`);
    
    // Blob erstellen (kalenderdatei)
    let bb = new Blob([icaltext], { type: 'text/calendar' });
  
    // Hochladen der Datei mit Firebase SDK
    uploadBytes(storageRef, bb).then(() => {
      console.log(`${filename} successfully uploaded!`);
  
      // Optional: Datei-URL abrufen
      getDownloadURL(storageRef).then((url) => {
        console.log('Download URL:', url);
      });
    }).catch((error) => {
      console.error('Error uploading file:', error);
    });
  },
  deleteZeit (state, payload) {
    let array = state.zeiten
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.zeiten = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
